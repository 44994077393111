.info-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  border-radius: 50%;
  background-color: #BE2327;
  color: white;
  font-style: normal;
  font-size: 12px;
  margin-left: 4px;
  cursor: pointer;
  vertical-align: middle;
  position: relative;
  top: -2px;
}

.tooltip-content {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  font-size: 14px;
  max-width: 300px;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.tooltip-content a {
  color: #fff;
  text-decoration: underline;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
  line-height: 1;
}

@media (max-width: 768px) {
  .tooltip-content {
    font-size: 16px;
    padding: 20px;
  }

  .close-button {
    top: 10px;
    right: 10px;
    font-size: 24px;
  }
}